.loginDisabled-container {
    text-align: center;
    margin-top: 40px;
  }
  
  .loginDisabled-box {
    background-color: #f7f7f7;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  }
  
  .loginDisabled-title {
    font-weight: bold;
    color: #333;
  }
  
  .loginDisabled-message {
    margin-top: 16px !important;
    color: #666;
  }
  
  .loginDisabled-button-container {
    margin-top: 24px;
  }
  
  .loginDisabled-button {
    font-weight: bold;
    pointer-events: none;
  }
  