.select-month-name {
    max-height: 200px !important;
    overflow: auto !important;
  }
  
@media (min-width: 600px) {
    .select-month-name {
    max-height: 450px !important;
    }
}

.desktop-dates {
    background-color: #808080 !important;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    color: var(--secondary-main) !important;
    cursor: default;
    font-size: 0.8em;
  }

.desktop-topic-paper {
    padding: 0 !important;
    border-radius: 0 !important;
  }
  
.desktop-topic-box {
    display: flex;
    align-items: center;
}

.desktop-textfield-input {
    padding: 0 !important;
    margin: 0 !important;
}

.desktop-description {
    padding: 0 !important;
    border-radius: 0 !important;
}

.desktop-menuitem {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-dates {
    background-color: #808080;
    border-radius: 6px;
  }
  
  .input-base-root {
    color: #fff !important;
  }
  
  .input-label-root {
    color: #fff !important;
  }
  
  .select-icon {
    color: #fff !important;
  }

.mobile-description-active {
    border: 2px solid #7F141A;
    border-radius: 5px;
  }
  
  .mobile-description-inactive {
    border: 1px solid rgb(197, 190, 190);
    border-radius: 5px;
  }  

.linear-progress {
    width: 100%;
    padding: 0;
    margin: 0;
    margin-bottom: 1rem;
  }

.button-styles {
    display: flex !important;
    justify-content: flex-end !important;
    margin-bottom: 3rem !important;
  }
  
  @media (max-width: 599px) {
    .box-styles {
      margin-bottom: 5rem !important;
    }
  }
  
  @media (min-width: 600px) and (max-width: 959px) {
    .box-styles {
      margin-bottom: 5rem !important;
    }
  }
  
  @media (min-width: 960px) {
    .box-styles {
      margin-bottom: 6rem !important;
    }
  }
  
  .button-alignment-1 {
    margin-right: 2rem !important;
  }
  
  .button-alignment-2 {
    margin-right: 1rem !important;
  }
  
.employee-name-styles {
    min-width: 100px;
}

.employee-working-weeks {
    max-width: 190px;
}

.weekly-datagrid {
    background-color: #fff !important;
    min-height: auto !important;
  }

.datagrid-description {
    white-space: normal !important;
    overflow-wrap: break-word !important;
  }
  
.datagrid {
    min-height: 170px;
  }
  
  .datagrid-selected-row-count {
    display: none;
  }
  
  .datagrid-cell {
    display: flex;
    align-items: center;
    padding: 5px;
  }
  
.weekly-report-datagrid {
    min-height: 170px;
  }
  
.weekly-report-datagrid .MuiDataGrid-selectedRowCount {
    display: none;
}

.weekly-report-datagrid .MuiDataGrid-cell {
    display: flex;
    align-items: center;
    padding: 5px;
}
