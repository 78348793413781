 .hierarchy-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    height: 100vh;
    background-color: #f5f5f5;
    position: relative;
}

.tree-node rect {
    fill: var(--primary-main);
    stroke: #1e1e1e;
    stroke-width: 1px;
}

.tree-node text {
    text-anchor: middle;
    stroke: none;
    fill: #fcf9f9;
}


