.appbar {
    background-color: #f5f5f5 !important;
    color: var(--primary-main) !important;
    border-bottom: 4px solid var(--primary-main);
  }
  
  .logo {
    height: 50px;
  }
  
  .menu-button {
    font-weight: bold !important;
  }
  
  .menu-icon {
    margin-left: 8px;
  }
  
  .drawer {
    width: 250px;
    color:var(--primary-main);
  }

/* Swiper-specific styling */
.mySwiper {
  height: 400px;
  width: 100%;
}

.swiper-wrapper {
  transition-timing-function: ease-in-out;
}

.swiper-slide {
  background-color: rgb(43, 95, 240);
  color: #fff;
  font-size: 25px;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide:nth-child(2) {
  background-color: rgb(15 118 110);
}

.swiper-slide:nth-child(3) {
  background-color: rgb(45 212 191);
}

.swiper-slide:nth-child(4) {
  background-color: rgb(153 246 228);
}

.swiper-slide:nth-child(5) {
  background-color: rgb(34 197 94);
}
