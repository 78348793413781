
  
  .upload-button-ellipsis {
    display: inline-block; 
    max-width: 120px;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    text-align: center; 
    line-height: 50px; 
    min-height: 50px; 
  }
  
  .upload-btn{
   min-width: 200px !important;
   padding: 0.8;
  }
  