.tiptap {
  padding: 5px 10px 5px 10px;
  height: 60px;
  overflow: auto;
  border: none !important;
  outline: none !important;

  :first-child {
    margin-top: 0;
  }

  p{
    margin: 0px;
    padding: 0px;
    font-size: 0.8rem;
  }

  ul{
    font-size: 0.8rem;
  }

}
button.is-active {  
  color: #7F141A;
  border-radius: 10px;
}

.button-group {
  display: grid;    
  grid-template-columns: repeat(2, 1fr);  
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  float: right;
  margin:auto;
  align-content: center;
}

.editor-container:hover .button-group {
  opacity: 1;
  visibility: visible;
}

p.is-editor-empty:first-child::before {
  color: #ccc;
  content: attr(data-placeholder);
  position: absolute;
  height: 0;
  pointer-events: none;
}
