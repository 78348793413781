.grid-container-header-holiday {    
    border-radius: 8px 8px 0 0;
    background-color: #fff;
    align-content: space-around;
    margin-bottom: 0.5rem;
    padding:0.2rem 1rem 0.2rem 1rem;    
    row-gap: 1rem;
}

.holiday-list-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column !important;
    gap: 0.5rem !important;
}

.holiday-title-wrapper {
    display: flex;
    align-items: center;
}

.holiday-title-wrapper span {
    font-weight: bold;
    white-space: nowrap;
}

.holiday-items-wrapper{
    display: flex;
    width: 100%;
    gap: 1;
}

.holiday-info-box {
    display: flex;
    align-items: center;
    margin-top: 1%;
    width: 50% !important;
    gap: 0.3rem !important;
    white-space: normal !important;
    line-height: 1.2 !important;
}

.holiday-info-text {
    color: #555;
    font-size: 0.75rem;
}

.holiday-download-box {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 1rem;
}

.holiday-download-button {
    white-space: nowrap;
    min-width: 110px;
    padding: 6px 12px;
}

.holiday-grid-container {
    padding: 16px;
    background-color: #fff;
}

.holiday-datagrid {
    width: 100%;
}

.holiday-datagrid .MuiDataGrid-selectedRowCount {
    display: none;
}

@media (min-width: 600px) {
    .holiday-download-box {
        margin-top: 0;
    }
}

@media (min-width: 600px) {
    .holiday-download-button {
        min-width: auto;
        padding: 8px 16px;
    }
}

@media (min-width: 600px) {
    .holiday-list-wrapper {
        flex-direction: row !important;
        align-items: center !important;
    }
}

@media (min-width: 1024px) {
    .holiday-list-wrapper {
        align-items: flex-start;
        flex-direction: row !important;
    }
}

@media (min-width: 430px) {
    .holiday-info-box {
        margin-left: -3px !important;
    }
}

@media (min-width: 760px) {
    .holiday-info-box {
        margin-left: 15px !important;
    }
}

@media (min-width: 1280px) {
    .holiday-info-box {
        white-space: nowrap !important;
    }
}
