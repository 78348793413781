.dialog {
    border-radius: 15px !important;
  }

  .dialogTitle {
    border-bottom: solid 2px;
    border-color: var(--primary-main);
    padding: 25px !important;
  }

  .dialogCloseIcon {
    position:absolute  !important ;
    right:8px;
    top: 20px;
  }
  .dialogActions {
    border-top: 1px solid #e0e0e0;
    padding: 15px !important;
   }

/* Confirmation dialog style classes */
.confirmDialogTitle {
  display: flex;
  align-items : center ;
  justify-content: space-between ;
  font-weight: bold !important;
  padding: 10px 0px 10px 15px !important; 
}

.confirmDialogContent {
  padding: 0px !important;
}

.confirmDialogMsg {
 background: transparent !important;
 margin: 15px 5px;
}
