.employee-carousel-wrapper  {
    text-align: center;
    position: relative;
  }
  
.employee-carousel-card-container {
    margin: auto;
    width: 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 3px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
    /* padding-left:10px !important;
    padding-right:10px !important;
    padding-top:10px !important; */
    background-color: rgb(232, 234, 235) !important;

}

.employee-wrap-container{
  margin-top: 0.5rem;
  padding-bottom: 1px
}

.carousel-arrow-left {
    position: absolute !important;
    left: 0;
    top: 50%;
    z-index: 1;
}

.carousel-arrow-right {
    position: absolute !important;
    right: 0;
    top: 50%;
    z-index: 1;
}

.carousel-media{
    width: 100%;
    height: 160px;
    border-Radius: 2;
    object-position: top;
}

.carousel-card-content{
    width: 100%;
    padding: 5px !important;
    display: flex;
    /* align-items: left; */
}

.grid-container {
    display: flex !important;
    align-items: center !important;
}
  
.dashboard-title {
    font-weight: bold;
    padding-left: 5px;
}

.dashboard-content-wrapper {
    align-content: space-around;
    padding-left: 0.3rem;
    padding-right: 0.5rem;
    margin-bottom: 0.5rem;
    row-gap: 2rem;
    background-color: #fff !important;
    border-top-left-radius: '8px';
    border-top-right-radius: '8px';
    padding-top: 2px !important;
}

.employee-carousel-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: #fff;
  }

  .employee-carousel-grid {
    padding-bottom: 15px;
  }

  .employee-carousel-grid-item {
    display: flex;
    justify-content: center;
    align-items: stretch;
  }
  
  .employee-carousel-box {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .employee-carousel-content {
    text-align: center;
    align-items: center;
    margin-bottom: 10px;
  }

  .employee-name {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  .box-wrapper{
    border-radius: 5px !important; 
    margin-bottom: 5px !important;
  }
  