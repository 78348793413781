/* For Autocomplete Input Field */
  
  @media (max-width: 499px) {
    .custom-autocomplete .MuiAutocomplete-input {
       min-width: 25px !important;    /* Testing width for mobile screen */
    }
  }

  /* @media (min-width: 361px) and (max-width: 499px) {
    .custom-autocomplete .MuiAutocomplete-input {
      min-width: 25px !important;
    }
  } */

  @media (min-width: 500px) {
    .custom-autocomplete .MuiAutocomplete-input {
      min-width: 40px !important;
    }
  }
  

  
  
  
  
  