table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin:8px 0;
  }
  
  td, th {
    font-size: .8rem;
    border: 1px solid #dddddd;
    text-align: left;
    padding: 2px;
  }
  
  tr:nth-child(even) {
    background-color: #dddddd;
  }

  
 