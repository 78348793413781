.it-declaration-container{
    padding: 16px;
    background-color: #fff;
    margin-bottom: 50px;
}

.radio-group {
    display: flex !important;
    flex-direction: row !important;
  }
  
.radio-group .MuiFormControlLabel-label {
    font-weight: normal !important;
    font-size: 13px !important;
}

.radio-small .MuiRadio-root {
    transform: scale(0.75);  
}

th{
    text-align: center;
    padding: 0.7rem;
    /* font-size: 1rem; */
}

table{
    border-collapse: 'collapse';
    width: 100%;
}

.align-itdeclaration-buttons-right{
    display: flex;
    justify-content: end;
    gap:10px;
  }

.ITD-upload-proof-align-right{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* styles for table  */

.section {
    background-color: #d9eaf7;
    font-weight: bold;
    text-align: center !important;
    padding: 5px 10px;
}

.category {
    text-align: center;
    font-weight: bold;
}

.textfield-td{
    padding: 5px 10px 5px 10px;
}

.text-fields{
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.category-tr{
    background-color: transparent !important;
}

.disabled-text{
    color: #ff0000;
}

/* popup styles  */

.dc-it-declaration {
    display: flex;
    flex-direction: column;
}

.file-upload-field {
    border: 2px dashed #cccccc;
    padding: 20px;
    text-align: center;
    margin: 15px 0px 10px 0px ;

}

/* upload proof styles */
.link {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer !important;
    color: #0000FF !important;
    text-decoration: none !important;
  }
  .link:hover {
    text-decoration: underline !important;
  }

  .text-area {
      width: 95% ;
      height: 50px !important;
      resize: none;
      padding: 8px ;
      border-radius: 4px;
      font-size: 14px;
      outline: none;
  }