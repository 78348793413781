.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.main-content {
  display: flex;
    flex-direction: column;
    min-height: calc(100vh - 80px - 40px); /* header-footer*/
    padding-left: 72px; 
    padding-right: 16px; 
    padding-top: 80px; 
    background-color: var(--secondary-main); 
    overflow: auto;
  }
.main-content-height {
  height :calc(100vh - 80px - 40px);
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
}


@media all and (min-width:321px) and (max-width: 568px) {
  .header-center{
    margin: auto;
    text-align: center; 
    color: #111111;
    font-weight: bolder;
    font-size: 1em;
    margin-top: 50px !important;
  }
  .main-content {
    padding-left: 16px; 
  }

}


@media (min-width: 568px) {
    .header-center{
      margin: auto;
      text-align: center; 
      color: #111111;
      font-weight: bolder;
      font-size: 1.5em;
      margin-top: 20px !important;
    }
}
/* .grid-container-header {
  height: auto !important; 
  padding-top: 8px !important;
  padding-bottom: 8px !important;
} */

.grid-container-header {    
  border-radius: 8px 8px 0 0;
  background-color: #fff;
  align-content: space-around;
  margin-bottom: 0.5rem;
  padding:0.4rem 1rem 0.4rem 1rem;    
  row-gap: 1rem;
  font-weight: bold;
}

.grid-container-header-with-nofilters {    
  padding:0.8rem 1rem 0.8rem 1rem !important;      
}

.align-header-center{
  display: flex;  
  align-items: center;
}

.align-filters-right{
  display: flex;
  justify-content: end;
  gap:10px;
  align-items: center;
}

.search-textbox{
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 16px;
  background-color: #F6F8FC; /*theme.palette.secondary.main;*/
  padding:2px;
}


/* @media (min-width: 960px) { 
  .grid-container-header {
    height: 70px !important;
  }
} */

.info-icon{
  color: #1976d2;
}

.status-switch {
  font-size: 12px !important;
}

.filter-status-toggle{
  display: flex;
  width: 80px;
}


/*Drawer Slider scrollbar styles*/

.drawer-slider-list {  
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 0px 10px 10px 0px;  
}

@media (min-width: 600px) {
  .drawer-slider-list {
    border-top-right-radius: 0px;
  }
}

.drawer-slider-list::-webkit-scrollbar {
  width: 6px;
}

.drawer-slider-list::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 1em;
}

.drawer-slider-list::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
  margin-bottom: 8px;
  margin-top:8px
}

/* Footer styles*/

.footer-text{
  height:30px;
  margin: 8px 0;
}

@media (min-width: 600px) {
  .footer-text{
    margin-bottom: 0px;
  }
}

/* linear progress loading styles progress-loading and dc-bottom-padding */
.progress-loading{
  position: absolute !important;
  left : 0;
  width: 100%;  
}

.dc-bottom-padding{
  padding-bottom: 0px !important;
}

/* timesheet tracker styles */
.timetracker-legend{
  width : 16px;
  height : 16px;  
  margin-right : 6px; 
  border: 1px solid #A0A0A0;
}

.tt-filled-color{
  background-color : #B6FFA1;
}

.tt-weekend-color{
  background-color : #FEFFA7;
}

.tt-workingday-color{
  background-color : #ffffff;  
}

.tt-calender-date{
  position: relative;
  padding: 10px;
  text-align: center;
  border: 1px solid #A0A0A0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.css-poa6bm-MuiButtonBase-root-MuiIconButton-root{
  padding:2px !important;  
}

.css-j5uiqf-MuiFormControl-root-MuiTextField-root textarea{
  padding: 5px 10px !important;
}