.itd-reports-container{
    padding: 16px;
    background-color: #fff;
    margin-bottom: 50px;
}

.dropdown-regime-selection {
    width: 45%;
  }
  
  @media (min-width: 600px) {
    .dropdown-regime-selection {
      width: 20%;
    }
  }
  
  @media (min-width: 960px) {
    .dropdown-regime-selection {
      width: 15%;
    }
  }

  .formcontrols{
    display: flex;
    align-items: center;
    gap: 20px;
  }