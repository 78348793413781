
.reject-leave-form-control {
    max-height: 1rem;
    padding: 0.31rem;
    margin-left: 0px !important;
    border: 1px solid var(--primary-main);
}

.reject-leave-form-control .MuiSvgIcon-root {
    font-size: 1rem;
}

.reject-leave-form-control .MuiFormControlLabel-label {
    font-size: 0.9rem;
    font-weight: normal;
}
